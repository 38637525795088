<nz-upload
  (nzChange)="handleChange($event)"
  [nzBeforeUpload]="beforeUpload"
  [nzDisabled]="true"
  [nzShowUploadList]="false"
  class="avatar-uploader"
  nzAction="https://www.mocky.io/v2/5cc8019d300000980a055e76"
  nzListType="picture-card"
  nzName="avatar">
  <ng-container *ngIf="!currentUser?.signedUrl">
    <div class="ant-upload-text">
      {{
        [currentUser?.name?.firstName, currentUser?.name?.lastName].join(' ')
          | shortName
      }}
    </div>
  </ng-container>
  <img
    *ngIf="currentUser?.signedUrl"
    [src]="currentUser?.signedUrl"
    style="width: 100%" />
</nz-upload>
<div class="upload-text">
  Dr. {{ currentUser?.name?.firstName }} {{ currentUser?.name?.lastName }}
</div>
<ul class="upload-list">
  <li>{{ 'userPopover.profile' | translate }}</li>
  <li>{{ 'userPopover.settings' | translate }}</li>
  <li>
    {{ 'userPopover.language' | translate }}
    <nz-select
      (ngModelChange)="handlerLanguageChange($event)"
      [ngModel]="lg"
      style="margin-left: 5px">
      <nz-option nzLabel="En" nzValue="en"></nz-option>
      <nz-option nzLabel="Es" nzValue="es"></nz-option>
    </nz-select>
  </li>
  <!--  <li *ngIf='departments'>-->
  <!--    <span style='font-size: 12px; padding-right: 5px'>Departments</span>-->
  <!--    <nz-select (ngModelChange)='handlerSelectChange()' [(ngModel)]='department'-->
  <!--               [nzShowArrow]='true'-->
  <!--               class='aiomed-patient-card__symptoms'-->
  <!--               nzPlaceHolder='Select current department'-->
  <!--               nzSize='small'-->
  <!--               style='width: 120px'-->
  <!--    >-->
  <!--      <nz-option *ngFor='let department of departments'-->
  <!--                 [nzLabel]='department.name'-->
  <!--                 [nzValue]='department.id'></nz-option>-->
  <!--    </nz-select>-->
  <!--  </li>-->
</ul>
<ul class="privet-policy-support">
  <li>
    <a href="https://www.aiomed.com/privacy-policy" target="_blank"
      >Privacy Policy</a
    >
  </li>
  <li>
    <a
      href="https://aiomed.atlassian.net/rest/collectors/1.0/template/form/9374ba31"
      target="_blank"
      >Support</a
    >
  </li>
  <li>
    <a type="button" (click)="reportIssue.emit()">{{
      'userPopover.report-issue' | translate
    }}</a>
  </li>
  <li>
    Version - ({{
      appVersion === 'Unknown'
        ? 'Unknown'
        : (appVersion | date: 'HH:mm-M.d.yy')
    }})
  </li>
</ul>
