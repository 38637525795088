import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { NotAuthenticated } from '../core/guards/not-authenticated.guard'
import { IsAuthenticated } from '../core/guards/is-authenticated.guard'
import { ROUTE_DATA } from '../core/helpers/variables'
import { isAutomaticGuard } from '../core/guards/is-automatic.guard'
import { nativeDeviceGuard } from '../core/guards/native-device.guard'

const routes: Routes = [
  {
    path: 'login',
    canActivate: [NotAuthenticated],
    canLoad: [NotAuthenticated],
    loadChildren: () =>
      import('./pages/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'reset',
    loadChildren: () =>
      import('./pages/reset-password/reset-password.module').then(
        m => m.ResetPasswordModule
      )
  },
  {
    path: 'devices',
    canLoad: [IsAuthenticated, isAutomaticGuard],
    canActivate: [IsAuthenticated, isAutomaticGuard],
    data: {
      ...ROUTE_DATA.devices
    },
    loadChildren: () =>
      import('./pages/devices/devices.module').then(m => m.DevicesModule)
  },
  {
    path: 'insights',
    canLoad: [IsAuthenticated],
    canActivate: [IsAuthenticated],
    data: {
      ...ROUTE_DATA.patient
    },
    loadChildren: () =>
      import('./pages/insights/insights.module').then(m => m.InsightsModule)
  },
  {
    path: 'patient/:patientId',
    canLoad: [IsAuthenticated],
    canActivate: [IsAuthenticated],
    data: {
      ...ROUTE_DATA.patient
    },
    loadChildren: () =>
      import('./pages/patient/patient.module').then(m => m.PatientModule)
  },
  {
    path: 'insight/:insightId',
    canLoad: [IsAuthenticated],
    canActivate: [IsAuthenticated],
    data: {
      ...ROUTE_DATA.patient
    },
    loadChildren: () =>
      import('./pages/insight/insight.module').then(m => m.InsightModule)
  },
  {
    path: 'all-patients',
    canLoad: [IsAuthenticated],
    canActivate: [IsAuthenticated],
    data: {
      ...ROUTE_DATA.all_patients
    },
    loadChildren: () =>
      import('./pages/all-patients/all-patients.module').then(
        m => m.AllPatientsModule
      )
  },
  {
    path: 'reports',
    canLoad: [IsAuthenticated],
    canActivate: [IsAuthenticated],
    data: {
      ...ROUTE_DATA.patient
    },
    loadChildren: () =>
      import('./pages/reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'alerts',
    canLoad: [IsAuthenticated, isAutomaticGuard],
    canActivate: [IsAuthenticated, isAutomaticGuard],
    data: {
      ...ROUTE_DATA.alerts
    },
    loadChildren: () =>
      import('./pages/alerts/alerts.module').then(m => m.AlertsModule)
  },
  {
    path: 'shift-planer',
    canLoad: [IsAuthenticated],
    canActivate: [IsAuthenticated],
    loadChildren: () =>
      import('./pages/shift-planer/shift-planer.module').then(
        m => m.ShiftPlanerModule
      )
  },
  {
    path: 'report/:id',
    canLoad: [IsAuthenticated],
    canActivate: [IsAuthenticated],
    loadComponent: () =>
      import('./pages/report/report.component').then(c => c.ReportComponent)
  },
  {
    path: 'notifications',
    canLoad: [IsAuthenticated],
    canActivate: [IsAuthenticated],
    loadChildren: () =>
      import('./pages/notifications/notifications.module').then(
        m => m.NotificationsModule
      )
  },
  {
    path: '',
    canLoad: [IsAuthenticated, nativeDeviceGuard],
    canActivate: [IsAuthenticated, nativeDeviceGuard],
    data: {
      ...ROUTE_DATA.patients
    },
    loadChildren: () =>
      import('./pages/patients/patients.module').then(m => m.PatientsModule)
  }
  // {
  // 	path: 'create-measurement',
  // 	canLoad: [IsAuthenticated],
  // 	canActivate: [IsAuthenticated],
  // 	loadChildren: () =>
  // 		import('./pages/create-measurement/create-measurement.module').then(
  // 			(m) => m.CreateMeasurementModule
  // 		)
  // },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
