import { Selector, State } from '@ngxs/store'
import {
  DataAction,
  Payload,
  StateRepository
} from '@angular-ru/ngxs/decorators'
import { Injectable } from '@angular/core'
import { AlertState } from './alert/alert.state'
import { EntityDictionary } from '@angular-ru/cdk/entity'
import {
  Alert,
  AlertDTO,
  AlertResolution,
  alertUrgencyComparator,
  UpdateAlertInterface
} from '../shared/model/alert'
import { PatientState } from './patient/patient.state'
import { PatientDTO, PatientInterface } from '../shared/model/patient'
import { EntityCollation } from './root-store-common'
import {
  catchError,
  ignoreElements,
  merge,
  mergeMap,
  Observable,
  of,
  tap
} from 'rxjs'
import { FileState } from './file/file.state'
import { FileDTO } from '../shared/model/file'
import { DeviceState } from './device/device.state'
import { DeviceDTO } from '../shared/model/device.model'
import { AlertRuleDTO } from '../shared/model/alert-rules.model'
import { AlertRuleState } from './alert-rule/alert-rule.state'
import { AlertsBrowsingState } from './alerts-browsing/alerts-browsing.state'
import { UserState } from './user/user.state'
import { BackendService } from '../shared/services/backend.service'
import {
  ObservationField,
  ObservationsLatestMap,
  PatientObservationDTO
} from '../shared/model/patient-observation'
import { LoginRequestInterface } from '../shared/model/auth.model'
import { PccState } from './pcc/pcc.state'
import { HttpClient } from '@angular/common/http'
import { environment } from '../environments/environment'
import { NotificationService } from '../shared/services/notification.service'
import { MeasurementState } from './measurement/measurement.state'
import { orderBy } from 'lodash-es'
import { EntitiesByNames } from '../core/enums/entities.enums'
import { TaskState } from './task/task.state'
import { PreferenceState } from './preference/preference.state'
import { TaskPatientInterface } from '../shared/model/task.model'
import { ScreenBlockedStatus } from '../shared/model/preference.model'
import { StoreEventsService } from './store-events.service'
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories'
import { DepartmentState } from './department/department.state'
import { DepartmentFilter } from '../shared/model/departments.model'

@StateRepository()
@State({
  name: 'rootSelectors'
})
@Injectable()
export class RootStore extends NgxsDataRepository<EntityCollation> {
  lastData: ObservationField[] = []

  constructor(
    private alertState: AlertState,
    private patientState: PatientState,
    private alertRule: AlertRuleState,
    private alertsBrowsingState: AlertsBrowsingState,
    private userState: UserState,
    private backendService: BackendService,
    private http: HttpClient,
    private pccState: PccState,
    private ntfService: NotificationService,
    private departmentState: DepartmentState,
    private storeEvents: StoreEventsService,
    private fileState: FileState
  ) {
    super()
  }

  @Selector([RootStore, AlertState.alerts, PatientState.allDepartmentPatients])
  public static devicesAlertsCount(
    state: EntityCollation,
    alerts: EntityDictionary<string, AlertDTO>,
    patients: Partial<PatientInterface>[]
  ): number {
    let currentAlerts: AlertDTO[] = []
    patients.forEach(p => {
      const alert = Object.values(alerts).find(
        a => a.patient && a.patient.id === p.id
      )
      if (alert) {
        currentAlerts = [...currentAlerts, alert]
      }
    })
    return currentAlerts
      .filter(a => a.status === 'open')
      .filter((v: AlertDTO) => v.alertedDevice).length
  }

  @Selector([
    RootStore,
    RootStore.hydratedAlertsWithoutEscalated,
    TaskState.currentTasks,
    PreferenceState.screenSaver
  ])
  public static screenBlockedStatus(
    state: EntityCollation,
    alerts: AlertDTO[],
    tasks: TaskPatientInterface[],
    screenSaver: boolean
  ): any {
    const havAlert = !!Object.values(alerts)
      .filter(a => a.status === 'open')
      .filter((v: AlertDTO) => v.alertedDevice).length
    const activeTasks = !!tasks.filter(t => !t.isLaterTimeLine).length

    if (havAlert && screenSaver) {
      return ScreenBlockedStatus.RedBlocked
    } else if (!havAlert && activeTasks && screenSaver) {
      return ScreenBlockedStatus.BlueBlocked
    } else if (!havAlert && !activeTasks && screenSaver) {
      return ScreenBlockedStatus.Blocked
    } else {
      return ''
    }
  }

  @Selector([RootStore, MeasurementState.measurement, PatientState.patient])
  public static measurement(
    state: EntityCollation,
    measurement: EntityDictionary<string, PatientObservationDTO>,
    patient: PatientInterface | null
  ): ObservationField[] {
    let data: ObservationField[] = []
    if (patient) {
      Object.keys(measurement).forEach(key => {
        if (key === patient?.id) {
          data = orderBy(
            Object.values(measurement[key].observations),
            'timestamp',
            'asc'
          )
        }
      })
      return data
    }
    return data
  }

  @Selector([RootStore, MeasurementState.measurement, PatientState.patient])
  public static latestPerVital(
    state: EntityCollation,
    measurement: EntityDictionary<string, PatientObservationDTO>,
    patient: PatientInterface | null
  ): Partial<ObservationsLatestMap> {
    if (patient) {
      let data: Partial<ObservationsLatestMap> = {}
      Object.keys(measurement).forEach(key => {
        if (key === patient?.id) {
          data = measurement[key].latestPerVital!
        }
      })
      return data
    }
    return {}
  }

  @Selector([RootStore, MeasurementState.measurement, PatientState.patient])
  public static latestObjectPerVital(
    state: EntityCollation,
    measurement: EntityDictionary<string, PatientObservationDTO>,
    patient: PatientInterface | null
  ): Partial<PatientObservationDTO> {
    if (patient) {
      let data: Partial<PatientObservationDTO> = {}
      Object.keys(measurement).forEach(key => {
        if (key === patient?.id) {
          data = measurement[key]!
        }
      })
      return data
    }
    return {}
  }

  @Selector([RootStore, PccState.pccSentPatientVitals])
  public static pccSentPatientVitals(
    state: EntityCollation,
    pccSentPatientVitals: string[]
  ): string[] {
    return pccSentPatientVitals
  }

  @Selector([
    RootStore,
    AlertState.alerts,
    PatientState.entities,
    FileState.files,
    DeviceState.entities
  ])
  public static hydratedAlertsWithoutEscalated(
    state: EntityCollation,
    alerts: EntityDictionary<string, AlertDTO>,
    patients: EntityDictionary<string, PatientDTO>,
    files: EntityDictionary<string, FileDTO>,
    devices: EntityDictionary<string, DeviceDTO>
  ): Alert[] {
    let result = Object.values(alerts)
      .filter(a => a.resolution != AlertResolution.Escalated)
      .map(a => {
        const patient: PatientDTO | undefined = a.patient?.id
          ? patients[a.patient.id]
          : undefined
        const avatar = patient?.avatar?.id
          ? files[patient.avatar.id]
          : undefined
        const device: DeviceDTO | undefined = a.alertedDevice?.id
          ? devices[a.alertedDevice.id]
          : undefined

        return {
          ...a,
          patient: {
            ...a.patient,
            ...patient,
            ...{
              avatar: {
                ...patient?.avatar,
                ...avatar
              }
            }
          },
          alertedDevice: {
            ...a.alertedDevice,
            ...device
          }
        } as Alert
      })

    result.sort(alertUrgencyComparator)
    return result
  }

  @Selector([
    RootStore,
    AlertState.OpenAlerts,
    PatientState.allDepartmentPatients
  ])
  public static patientsAlertsCount(
    state: EntityCollation,
    alerts: AlertDTO[],
    patients: Partial<PatientInterface>[]
  ): number {
    let currentPatientAlertsCount = 0
    patients.forEach(p => {
      if (alerts.find(a => a.patient && a.patient.id === p.id)) {
        currentPatientAlertsCount += 1
      }
    })
    return currentPatientAlertsCount
  }

  public override ngxsOnInit() {
    this.setLoadAvatarProcessListener()
  }

  setLoadAvatarProcessListener() {
    merge(
      this.storeEvents.patientsModified$,
      this.storeEvents.departmentChange$
    )
      .pipe(
        tap(() => {
          const department = Object.values(this.departmentState.entities).length
            ? Object.values(this.departmentState.entities)[0]
            : this.departmentState.getState().currentDepartment
          let patients: PatientDTO[] = []
          if (department && department.id === DepartmentFilter.All) {
            patients = Object.values(this.patientState.entities).filter(
              p => p.avatar && p.avatar.id
            )
          } else if (department && department.id) {
            patients = Object.values(this.patientState.entities).filter(
              p =>
                p.avatar && p.avatar.id && p.department?.id === department?.id
            )
          }
          if (patients.length && patients.length > 0) {
            patients.forEach(patient => {
              if (patient.avatar?.id) {
                this.fileState.requestAvatar(patient.avatar.id)
              }
            })
          }
        })
      )
      .subscribe()
  }

  @DataAction()
  public updateAlert(
    @Payload('entityId') id: string,
    @Payload('update') data: UpdateAlertInterface
  ): Observable<void> {
    const user = this.userState.getState().user
    const entityDiff = data.resolution
      ? {
          ...data,
          resolvedBy: {
            id: user?.id,
            name: `${user?.name.lastName} ${user?.name.firstName}`,
            parentEntityId: null,
            templateId: (user as any)._template.id
          }
        }
      : {
          ...data,
          resolvedBy: null
        }
    return this.backendService.updateAlert(id, entityDiff).pipe(
      mergeMap(alert => {
        this.alertState.removeOne(alert.id)
        this.alertsBrowsingState.upsertOne(alert)
        return of()
      }),
      ignoreElements()
    )
  }

  @DataAction()
  public checkBiotLoginAndUpdatePccEmrVitals(
    @Payload('req') req: LoginRequestInterface,
    @Payload('patientId') patientId: string,
    @Payload('data') data: ObservationField
  ): Observable<void> {
    // @ts-ignore
    return this.http
      .post(`${environment.apiUrl}/ums/v2/users/login`, {
        ...req
      })
      .pipe(
        tap(() => {
          this.pccState.updatePccEmrMeasurement({
            observedPatient: patientId,
            ...data
          })
        }),
        catchError(() => {
          return of(null)
        }),
        ignoreElements()
      )
  }

  @DataAction()
  public createAlertRule(
    @Payload('alertRule') alertRule: AlertRuleDTO,
    @Payload('patientId') patientId: string
  ) {
    return this.backendService.addAlertRule(alertRule).pipe(
      tap(res => {
        // this.patientState.upsertOne()
        this.patientState.updatePatient(patientId, {
          alertRules: {
            id: res.id,
            name: res.name,
            templateName: EntitiesByNames.AlertRules
          }
        })
        // @ts-ignore
        // const currentPatient: PatientDTO = Object.values(
        // 	this.patientState.entities
        // ).find((p) => p.id === patientId)
        // this.patientState.upsertOne({
        // 	...currentPatient,
        // 	alertRules: {
        // 		id: res.id,
        // 		name: res.name,
        // 		templateId: 'c159c6bf-8aed-4965-b1cf-4b7015a19948'
        // 	}
        // })
        this.alertRule.upsertOne(res)
        this.ntfService.success(`Alert rule has been created`)
      })
    )
  }
}
