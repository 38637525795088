export enum FeatureParams {
  FeatureStrictSystemTime = 'featureStrictSystemTime',
  FeatureNotifierTime = 'featureNotifierTime',
  FeatureNotifierTimeRepetition = 'featureNotifierTimeRepetitionInterval',
  FeatureNotifierEnabled = 'featureNotifierEnabled',
  FeatureOfflineDebounceTime = 'featureOfflineDebounceTime',
  FeatureVideoTutorialControlsShown = 'featureVideoTutorialControlsShown',
  FeatureSessionExpirationDuration = 'featureSessionExpirationDuration',
  FeatureScreenSaverDebounceTime = 'featureScreenSaverDebounceTime'
}

export interface Config {
  featureStrictSystemTime: boolean
  featureNotifierTime: number
  featureNotifierTimeRepetitionInterval: number
  featureNotifierEnabled: boolean
  featureVideoTutorialControlsShown: boolean
  featureOfflineDebounceTime: number
  featureSessionExpirationDuration: number
  featureScreenSaverDebounceTime: number
}
