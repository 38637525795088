import { environment as base } from './environment.base'

export const environment = {
  ...base,
  production: true,
  test: false,
  measurementsApiUrl:
    'https://preproduction-measurement-service-6yuxfti4qa-uk.a.run.app',
  pccIntegrationApiUrl:
    'https://preproduction-pointclickcare-integration-6yuxfti4qa-uk.a.run.app',
  tasksApiUrl: 'https://preproduction-task-service-6yuxfti4qa-uk.a.run.app',
  reportsApiUrl:
    ' https://preproduction-report-service-6yuxfti4qa-uk.a.run.app',
  authApiUrl: 'https://auth.aiomed.com',
  biobeatIntegrationApiUrl:
    'https://preproduction-biobeat-integration-6yuxfti4qa-uk.a.run.app',
  forceReadWsUrl:
    'wss://preproduction-biobeat-integration-6yuxfti4qa-uk.a.run.app',
  apiUrl: 'https://api.biot.aiomed.com',
  notificationApiUrl:
    'https://preproduction-notifications-service-6yuxfti4qa-uk.a.run.app',
  medicationApiUrl:
    'https://preproduction-medication-service-6yuxfti4qa-uk.a.run.app',
  productFruitsTabletCode: 'lYSI502tLWf7LxmS',
  productFruitsCode: 'noNgNadTs2YFvlnp',
  logrocketAppID: 'focbyk/linc'
}
