import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { NzUploadFile } from 'ng-zorro-antd/upload'
import { Observable, Observer } from 'rxjs'
import { UserInterface } from '../../../../../shared/model/user.model'
import { DepartmentDTO } from '../../../../../shared/model/permission.model'
import { CurrentAppVersion } from '../../../../../shared/services/new-version-checker.service'

@Component({
  selector: 'aiomed-user-popover',
  templateUrl: './user-popover.component.html',
  styleUrls: ['./user-popover.component.scss'],
  standalone: false
})
export class UserPopoverComponent implements OnInit {
  @Input() currentUser: UserInterface | null
  @Input() departments: DepartmentDTO[] | null
  @Input() currentDepartment: DepartmentDTO | null
  @Input() language: string | null
  @Input() appVersion: CurrentAppVersion
  @Output('logoutEmitter') logout = new EventEmitter()
  @Output('changeDepartment') changeDepartment = new EventEmitter()
  @Output('changeLanguageEmitter') changeLanguage = new EventEmitter()
  @Output() reportIssue = new EventEmitter<void>()
  loading = false
  avatarUrl?: string
  department: string
  lg: string = 'en'

  ngOnInit(): void {
    this.firstInnitDepartment()
    this.lg = !this.language ? 'en' : this.language
  }

  firstInnitDepartment(): void {
    this.currentDepartment
      ? (this.department = this.currentDepartment.id)
      : (this.department = 'all')
  }

  beforeUpload = (
    file: NzUploadFile,
    _fileList: NzUploadFile[]
  ): Observable<boolean> =>
    new Observable((observer: Observer<boolean>) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        observer.complete()
        return
      }
      const isLt2M = file.size! / 1024 / 1024 < 2
      if (!isLt2M) {
        observer.complete()
        return
      }
      observer.next(isJpgOrPng && isLt2M)
      observer.complete()
    })

  handleChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.loading = true
        break
      case 'done':
        // Get this url from response in real world.
        this.getBase64(info.file!.originFileObj!, (img: string) => {
          this.loading = false
          this.avatarUrl = img
        })
        break
      case 'error':
        this.loading = false
        break
    }
  }

  handlerSelectChange() {
    this.changeDepartment.emit(this.department)
  }

  handlerLanguageChange($event: string) {
    this.changeLanguage.emit($event)
  }

  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result!.toString()))
    reader.readAsDataURL(img)
  }
}
