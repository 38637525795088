<lib-side-menu
  (avatarClick)="showPopover()"
  (isOpenedMenuChange)="toggleSidenav($event)"
  (listItemClick)="itemClick()"
  (logout)="openConfirmLogout()"
  [(popoverVisible)]="popoverVisible"
  [customTemplateContent]="rn"
  [departmentName]="isMobile ? (currentDepartment$ | async)?.name || '-' : ''"
  [deviceName]="isMobile ? (currentUserDevice$ | async)?.id || '-' : ''"
  [isMobile]="isForcedResolution$ | async"
  [isOpened]="!(isCollapsedMenu$ | async)"
  [isUserRN]="!(isUserCNA$ | async)"
  [items]="sideMenuItems$ | async"
  [logoutHidden]="hasCNAAccessToken$ | async"
  [nzPopoverContent]="contentTemplate"
  [user]="user$ | async" />
<ng-template #rn>
  @if (isMobile) {
    <button class="rn-btn reload" (click)="refreshPage()">
      <span nz-icon nzType="reload" nzTheme="outline"></span
      ><span>Refresh</span>
    </button>
    @if (
      !(isUserCNA$ | async) &&
      (currentUserDevice$ | async)?.id !== '-' &&
      (currentDepartment$ | async)
    ) {
      <button class="rn-btn" (click)="showSpotlightTable()">
        <span nz-icon nzType="aio:menu-spotlight-table" nzTheme="outline"></span
        ><span>Spotlight table</span>
      </button>
    }
    <button
      [attr.data-cy]="'device-pairing'"
      class="rn-btn"
      (click)="pairDevices()">
      <span nz-icon nzType="link" nzTheme="outline"></span
      ><span>Device pairing</span>
    </button>
    @if ((isUserCNA$ | async) && !(isPccUser$ | async)) {
      <button class="rn-btn" (click)="showModalLoginToPcc()">
        <span nz-icon nzType="aio:stethoscope"></span><span>Nurse sign in</span>
      </button>
    } @else {
      @if (hasCNAAccessToken$ | async) {
        <button
          nz-button
          class="rn-btn"
          [class.no-border]="hasCNAAccessToken$ | async"
          [nzLoading]="userLoading$ | async"
          (click)="handlerClickRnLogout()">
          <span nz-icon nzType="aio:heart-in-hands"></span
          ><span>Nurse sign out</span>
        </button>
      }
    }
    <nz-modal
      nzWidth="618px"
      nzMaskClosable="false"
      [nzVisible]="isPccLogging$ | async"
      (nzOnCancel)="hideModalLoginToPcc()"
      [nzFooter]="null"
      nzWrapClassName="pcc-login-modal tablet-center tablet-modal-wrapper"
      [nzStyle]="{
        top: '50%',
        transform: 'translateY(-50%)'
      }"
      nzNoAnimation>
      <ng-container *nzModalContent>
        <div class="pcc-login-modal__header">
          <div class="title">Nurse login</div>
          <div class="title-close" (click)="hideModalLoginToPcc()">
            Close
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none">
              <path
                d="M24.5427 21.9972L37.4334 6.63159C37.6494 6.37623 37.4678 5.98828 37.1338 5.98828H33.2151C32.9843 5.98828 32.7633 6.09141 32.6111 6.26819L21.9794 18.9427L11.3477 6.26819C11.2003 6.09141 10.9794 5.98828 10.7436 5.98828H6.82489C6.49096 5.98828 6.30927 6.37623 6.52534 6.63159L19.416 21.9972L6.52534 37.3628C6.47694 37.4198 6.44588 37.4894 6.43587 37.5634C6.42585 37.6374 6.43729 37.7128 6.46883 37.7805C6.50037 37.8483 6.55069 37.9055 6.6138 37.9455C6.67691 37.9855 6.75018 38.0065 6.82489 38.0061H10.7436C10.9744 38.0061 11.1954 37.903 11.3477 37.7262L21.9794 25.0517L32.6111 37.7262C32.7584 37.903 32.9794 38.0061 33.2151 38.0061H37.1338C37.4678 38.0061 37.6494 37.6182 37.4334 37.3628L24.5427 21.9972Z"
                fill="#263558" />
            </svg>
          </div>
        </div>
        <div *ngIf="!loginWithCred" class="pcc-login-modal__description">
          Nurses, please log in with PCC credentials first
        </div>
        <aiomed-login-tablet-nurse
          tab="rn"
          [hasError]="!!(errorMessage$ | async)"
          [isLoading]="isAuthLoading$ | async"
          [isMobile]="true"
          (loginWithCredEmitter)="loginWithCred = $event"
          (loginWithPcc)="loginWithPcc()"
          (formSubmitEmitter)="handlerFormSubmitEmitter($event)"
          (loginWithCredEmitter)="closeOpenedWindow()" />
      </ng-container>
    </nz-modal>
    <nz-modal
      nzCentered
      [nzVisible]="isPCCAccountUsagePopupVisible$ | async"
      [nzTitle]="'Log-in Settings'"
      (nzOnCancel)="hidePCCAccountUsagePopup()"
      (nzOnOk)="allowPCCAccountUsage()"
      nzCancelText="No"
      nzOkText="Yes, Enable"
      nzClassName="consent-modal">
      <ng-container *nzModalContent>
        You can simplify your future login process by using only your
        PointClickCare credentials. Would you like to enable this?
      </ng-container>
    </nz-modal>
    <nz-modal
      nzMaskClosable="false"
      [nzVisible]="isShowDevicePairing || isLogoutConfirmModalOpened"
      [nzFooter]="null"
      (nzOnCancel)="hideDevicePairing()"
      nzWrapClassName="tablet-center tablet-modal-wrapper"
      [nzClassName]="isDeviceChoosing ? 'device-pairing-modal' : ''"
      [nzStyle]="{
        top: '50%',
        transform: 'translateY(-50%)'
      }"
      nzNoAnimation
      [nzWidth]="isDeviceChoosing ? '720px' : '520px'">
      <ng-container *nzModalTitle>{{
        isDeviceChoosing ? 'Device Pairing' : 'ADMIN PIN CODE'
      }}</ng-container>
      <ng-container *nzModalContent>
        <div class="device-choosing" (keydown.enter)="handleEnterClick($event)">
          @if (isDeviceChoosing) {
            @if (sharedDevices$ | async; as devices) {
              <nz-form-item>
                <nz-form-label>Main vital machine</nz-form-label>
                <nz-select
                  [(ngModel)]="selectedDeviceId"
                  class="small"
                  nzDropdownClassName="custom-login-department-select"
                  nzPlaceHolder="Choose your machine">
                  @for (device of devices; track device.id) {
                    <nz-option
                      [nzValue]="device.id"
                      [nzLabel]="device.id"></nz-option>
                  }
                </nz-select>
                <label
                  nz-checkbox
                  [ngModel]="isDeviceDirectConnection$ | async"
                  (ngModelChange)="setDirectDeviceConnection($event)"
                  >Direct connection</label
                >
              </nz-form-item>
              <nz-form-item>
                <nz-form-label>Secondary device</nz-form-label>
                <nz-select
                  [nzShowSearch]="false"
                  [(ngModel)]="selectedSecondaryDevicesIds"
                  [nzShowArrow]="true"
                  nzMode="multiple"
                  class="small"
                  nzDropdownClassName="custom-login-department-select"
                  nzPlaceHolder="Choose your machine">
                  @for (device of devices; track device.id) {
                    <nz-option
                      [nzValue]="device.id"
                      [nzLabel]="device.id"></nz-option>
                  }
                </nz-select>
              </nz-form-item>
              <ng-template #nzMenuItemSelectedIcon>
                <span nz-icon nzType="check-square" nzTheme="outline"></span>
              </ng-template>
            }
            @if (allDepartments$ | async; as departments) {
              <nz-form-item>
                <nz-form-label>Department</nz-form-label>
                <nz-select
                  [(ngModel)]="selectedDepartmentId"
                  class="small"
                  nzDropdownClassName="custom-login-department-select"
                  nzPlaceHolder="Choose your department">
                  @for (department of departments; track department.id) {
                    <nz-option
                      [nzValue]="department.id"
                      [nzLabel]="department.name"></nz-option>
                  }
                </nz-select>
              </nz-form-item>
            }
            <button
              class="login-form__button connect"
              nz-button
              nzType="primary"
              [disabled]="
                !selectedDeviceId.length || !selectedDepartmentId.length
              "
              (click)="setCurrentDeviceAndDepartmentIds()">
              connect
            </button>
          } @else {
            <nz-input-number
              [attr.data-cy]="'admin-pincode'"
              #adminPinCodeField
              [formControl]="adminPinCode"
              [nzStatus]="
                adminPinCode.errors &&
                (adminPinCode.touched || adminPinCode.dirty)
                  ? 'error'
                  : ''
              "></nz-input-number>
            @if (
              adminPinCode.invalid &&
              (adminPinCode.touched || adminPinCode.dirty)
            ) {
              <small class="error">
                {{
                  adminPinCode.errors?.['minlength'] ||
                  adminPinCode.errors?.['maxlength']
                    ? 'PIN must be 4 digits long'
                    : adminPinCode.errors?.['required']
                      ? 'Please enter PIN code'
                      : 'Incorrect PIN. Please try again'
                }}
              </small>
            }
            @if (!isLogoutConfirmModalOpened) {
              <button
                [class.btn-tablet]="isMobile"
                nz-button
                nzType="primary"
                [disabled]="!adminPinCode.valid"
                (click)="showDeviceChoosing()">
                Go To Device Choosing
              </button>
            } @else {
              <button
                [class.btn-tablet]="isMobile"
                nz-button
                nzType="primary"
                [disabled]="!adminPinCode.valid"
                (click)="handlerClickLogout()">
                Confirm logout
              </button>
            }
          }
        </div>
      </ng-container>
    </nz-modal>
  }
</ng-template>
<ng-template #contentTemplate>
  <aiomed-user-popover
    (changeDepartment)="handlerChangeDepartment($event)"
    (changeLanguageEmitter)="handlerChangeLanguageEmitter($event)"
    (reportIssue)="openReportIssueModal()"
    [appVersion]="currentVersionTimestamp()"
    [currentUser]="user$ | async"
    [language]="language$ | async"></aiomed-user-popover>
</ng-template>

<aiomed-report-issue
  (cancelClick)="closeReportIssueModal()"
  (confirmClick)="reportIssueToLogRocket($event)"
  [isVisible]="reportIssueModalVisible"></aiomed-report-issue>

<aiomed-warning-modal
  (closeModal)="isShowWarning = false"
  (saveModalDataEmitter)="reLoginToRnMode()"
  *ngIf="isShowWarning && !(isOffline$ | async)"
  [isMobile]="isMobile"
  [width]="595"
  closeText="Close"
  description="You've been logged out due to inactivity. Please log in again to continue"
  saveText="Log in"
  title="YOU’VE BEEN LOGGED OUT"></aiomed-warning-modal>
<aiomed-warning-modal
  (closeModal)="isPccTokenInvalid = false"
  (saveModalDataEmitter)="isPccTokenInvalid = false"
  *ngIf="isPccTokenInvalid"
  [isMobile]="isMobile"
  [width]="595"
  closeText="Close"
  description="You've been logged out due to inactivity. Please log in again to continue"
  saveText="Log in"
  title="YOU’VE BEEN LOGGED OUT"></aiomed-warning-modal>

<ng-template #pccLoginTitle>
  <div class="pcc-login-modal__title">Nurse login</div>
</ng-template>
@if (isMobile) {
  <ng-container
    (deviceUpdatesEmitter)="handlerDeviceUpdatesEmitter()"
    aiomedReceivingData></ng-container>
}
