<section class="container auth-login__container">
  @if (isLoginWithCred) {
    <button class="role-back" type="button" (click)="isLoginWithCred = false">
      <span nz-icon nzType="arrow-left" nzTheme="outline"></span>Back
    </button>
  }
  <div
    class="auth-login__wrapper"
    [attr.pcc-login-shown]="true"
    [class.has-errors]="errorMessage$ | async">
    <div class="auth-login__content-box" [class.tablet]="isMobile">
      <div class="auth-login__logo">
        <img
          #imageToRefetch
          class="logo"
          src="assets/images/AiOmed_logo_small.svg"
          alt="AiOmed_logo" />
      </div>
      <aiomed-login-tablet-nurse
        (formSubmitEmitter)="handlerFormSubmitEmitter($event)"
        (loginWithPcc)="handlerLoginWithPcc()"
        [hasError]="!!(errorMessage$ | async)"
        tab="rn"
        [isLoginWithCred]="isLoginWithCred"
        [showForgotPassword]="false"
        [isLoading]="!!(isAuthLoading$ | async)"
        (loginWithCredEmitter)="
          isLoginWithCred = true
        "></aiomed-login-tablet-nurse>

      @if (needChooseDevice$ | async) {
        <div class="choose-device">
          @if (deviceLoading$ | async) {
            <div class="choose-device__spinner">
              <span nz-icon [nzType]="'loading'"></span>
            </div>
          } @else {
            @if (sharedDevices$ | async; as devices) {
              <nz-select
                [(ngModel)]="selectedDeviceId"
                class="small"
                nzDropdownClassName="custom-login-department-select"
                nzPlaceHolder="Choose your machine">
                @for (device of devices; track device.id) {
                  <nz-option
                    [nzValue]="device.id"
                    [nzLabel]="device.id"></nz-option>
                }
              </nz-select>
            }
            <button
              class="login-form__button"
              nz-button
              nzType="primary"
              [disabled]="!selectedDeviceId.length"
              (click)="setCurrentDeviceIdAndLogin()">
              Connect
            </button>
          }
        </div>
      }
    </div>
    <ul class="privet-policy-support">
      <li>
        <a href="https://www.aiomed.com/privacy-policy" target="_blank"
          >Privacy Policy</a
        >
      </li>
      @if (isLoginWithCred) {
        <li>
          <a [routerLink]="['/reset']" href="javascript:void(0);"
            >Forgot your password?</a
          >
        </li>
      }
      <li>
        <a
          href="https://aiomed.atlassian.net/rest/collectors/1.0/template/form/9374ba31"
          target="_blank"
          >Support</a
        >
      </li>
    </ul>
  </div>
  <div class="auth-login__greeting">
    <h3 class="auth-login__greeting--title">Hi, Welcome</h3>
    <p class="auth-login__greeting--description">Please log in</p>
    <img
      #imageToRefetch
      class="auth-login__greeting--image"
      src="assets/images/login-lock.svg"
      priority="true"
      width="325"
      height="305"
      alt="login-lock" />
  </div>
</section>

<aiomed-report-loader
  [loader]="(isAuthLoading$ | async) || (isPccReturning$ | async)"
  [isDark]="true"></aiomed-report-loader>
