import {
  CollatableEntityCollections,
  CollatableEntityCollectionsRepository,
  defaultEntityCollation,
  EntityCollation
} from '../root-store-common'
import {
  DataAction,
  Payload,
  StateRepository
} from '@angular-ru/ngxs/decorators'
import { Actions, Selector, State } from '@ngxs/store'
import {
  createEntityCollections,
  EntityDictionary
} from '@angular-ru/cdk/entity'
import { Injectable } from '@angular/core'
import {
  BehaviorSubject,
  combineLatest,
  concatMap,
  EMPTY,
  from,
  Observable,
  of,
  reduce,
  Subscription,
  tap
} from 'rxjs'
import {
  InsightDTO,
  InsightsAllActiveTotalCount,
  InsightsBackend
} from '../../shared/model/insight.model'
import { BackendService } from '../../shared/services/backend.service'
import { PatientState } from '../patient/patient.state'
import { FileState } from '../file/file.state'
import { PatientDTO, PatientInterface } from '../../shared/model/patient'
import { FileDTO } from '../../shared/model/file'
import moment from 'moment/moment'
import { StoreEventsService } from '../store-events.service'
import { DepartmentDTO } from '../../shared/model/permission.model'
import { DepartmentState } from '../department/department.state'
import { DepartmentFilter } from '../../shared/model/departments.model'
import { cloneDeep, orderBy } from 'lodash-es'
import { entitiesFilter } from '../../core/helpers/filter'
import { finalize } from 'rxjs/operators'
import { NotificationService } from '../../shared/services/notification.service'

export const insightFeatureName = 'insightBrowsing'

@StateRepository()
@State<CollatableEntityCollections<InsightDTO>>({
  name: insightFeatureName,
  defaults: {
    ...createEntityCollections(),
    ...defaultEntityCollation()
  }
})
@Injectable()
export class InsightBrowsingState extends CollatableEntityCollectionsRepository<
  InsightDTO,
  EntityCollation
> {
  private subscriptionGetAllInsights$: Subscription
  private insightsAllActiveTotalCount$ =
    new BehaviorSubject<InsightsAllActiveTotalCount>({
      activeTotalCount: 0,
      allTotalCount: 0
    })
  public readonly insightsAllActiveTotalCountObs$ =
    this.insightsAllActiveTotalCount$.asObservable()

  constructor(
    private backendService: BackendService,
    private actions: Actions,
    private patientState: PatientState,
    private storeEvents: StoreEventsService,
    private departmentState: DepartmentState,
    private ntfService: NotificationService
  ) {
    super()
  }

  @Selector()
  public static isLoading(
    state: CollatableEntityCollections<InsightDTO>
  ): boolean {
    return state.isLoading
  }

  @Selector([InsightBrowsingState, PatientState.patient])
  public static currentInsight(
    state: CollatableEntityCollections<InsightDTO>,
    patient: PatientInterface | null
  ): InsightDTO | null {
    if (state.currentInsights && patient) {
      return { ...state.currentInsights, patient }
    }
    return null
  }

  @Selector([
    InsightBrowsingState,
    PatientState.entities,
    FileState.files,
    DepartmentState.department
  ])
  public static insights(
    state: CollatableEntityCollections<InsightDTO>,
    patient: EntityDictionary<string, PatientDTO>,
    files: EntityDictionary<string, FileDTO>,
    department: DepartmentDTO | null
  ): InsightDTO[] {
    let patients = Object.values(patient)
    if (department && department.id !== DepartmentFilter.All) {
      patients = patients.filter(
        patient => patient.department && patient.department.id === department.id
      )
    }
    const currentInsights: any[] = []
    Object.values(state.entities).forEach(insight => {
      if (insight.patient && insight.patient.id) {
        const patient: PatientDTO | undefined = Object.values(patients).find(
          p => p.id === insight.patient?.id
        )
        if (patient) {
          currentInsights.push({
            ...insight,
            patient: {
              ...patient,
              // @ts-ignore
              avatar:
                patient.avatar &&
                files[patient.avatar.id] &&
                files[patient.avatar.id]?.signedUrl
                  ? files[patient.avatar.id]
                  : null
            }
          })
        }
      }
    })
    return currentInsights
  }

  @Selector([
    InsightBrowsingState,
    PatientState.entities,
    FileState.files,
    DepartmentState.department
  ])
  public static activeInsights(
    state: CollatableEntityCollections<InsightDTO>,
    patient: EntityDictionary<string, PatientDTO>,
    files: EntityDictionary<string, FileDTO>,
    department: DepartmentDTO | null
  ): InsightDTO[] {
    let patients = Object.values(patient)
    if (department && department.id !== DepartmentFilter.All) {
      patients = patients.filter(
        patient => patient.department && patient.department.id === department.id
      )
    }
    const currentInsights: any[] = []
    Object.values(state.entities).forEach(insight => {
      if (insight.patient && insight.patient.id) {
        const patient = Object.values(patients).find(
          p => p.id === insight.patient?.id
        )
        if (patient) {
          currentInsights.push({
            ...insight,
            patient: {
              ...patient,
              avatar:
                patient.avatar &&
                files[patient.avatar.id] &&
                files[patient.avatar.id]?.signedUrl
                  ? files[patient.avatar.id]
                  : null
            }
          })
        }
      }
    })
    return currentInsights.filter(insight => !insight.insightEndTime)
  }

  @Selector()
  public static insightsRelatedType(
    state: CollatableEntityCollections<InsightDTO>
  ): string {
    let insightsRelatedType = ''
    Object.values(state.entities)
      .filter(
        i => moment(new Date()).diff(moment(i.insightStartTime), 'hours') <= 48
      )
      .forEach(i => {
        if (!insightsRelatedType) {
          insightsRelatedType = i.insightSubject.split('_').join(' ')
        } else {
          insightsRelatedType = `${insightsRelatedType}, ${i.insightSubject
            .split('_')
            .join(' ')}`
        }
      })
    return insightsRelatedType
  }

  @Selector()
  public static totalCount(
    state: CollatableEntityCollections<InsightDTO>
  ): number {
    return state.totalCount
  }

  public override ngxsOnInit() {
    this.storeEvents.logout$
      .pipe(
        tap(() => {
          this.reset()
        })
      )
      .subscribe()
  }

  @DataAction()
  updateInsight(
    @Payload('id') id: string,
    @Payload('data') data: Partial<InsightDTO>,
    @Payload('withNtf') withNtf: boolean = false
  ) {
    return this.backendService.updateInsight(id, data).pipe(
      tap(res => {
        const insight: InsightDTO | null = cloneDeep(
          this.getState().currentInsights
        )
        if (insight) {
          this.patchState({
            currentInsights: { ...insight, isHelpful: (res as any).isHelpful }
          })
        }
        if (!withNtf) return
        this.ntfService.success(
          'Thank you for your feedback! \n' + 'Your input helps us improve.',
          0,
          1
        )
      })
    )
  }

  @DataAction()
  public loadPatientInsights(
    @Payload('page') page: number,
    @Payload('freeTextFilter') freeTextSearch: string,
    @Payload('patientId') patientId?: string,
    @Payload('needPatientsIds') needPatientsIds: boolean | undefined = false,
    @Payload('status') status?: string
  ) {
    this.ctx.patchState({
      isLoading: true
    })
    const allPatientIds = needPatientsIds
      ? this.searchInsightPatientByNameAndRoom(freeTextSearch).map(p => p.id)
      : []

    if (!needPatientsIds) {
      return this.loadCurrentPatientInsights(
        page,
        freeTextSearch,
        patientId!,
        status
      )
    }
    if (!allPatientIds.length && freeTextSearch.length) {
      this.upsertMany([])
      this.ctx.patchState({ isLoading: false })
      return of([])
    }
    const chunkedIds = this.backendService.chunkSetting(allPatientIds)

    return from(chunkedIds).pipe(
      concatMap(partIds =>
        this.backendService.getBrowsingInsights(
          page,
          freeTextSearch,
          patientId,
          partIds,
          status
        )
      ),
      reduce((acc: InsightsBackend[], res: InsightsBackend) => {
        acc.push(res)
        return acc
      }, [] as InsightsBackend[]),
      tap((allResponses: InsightsBackend[]) => {
        const combinedData = allResponses.flatMap(r => r.data ?? [])
        const insights = Object.values(this.getState().entities)

        this.removeAll()

        if (!combinedData.length && freeTextSearch.length) {
          this.upsertMany([])
        } else {
          const dataForUpsert =
            page === 0 ? combinedData : [...insights, ...combinedData]

          this.upsertMany(orderBy(dataForUpsert, 'insightStartTime', 'desc'))
        }

        if (!freeTextSearch && allResponses.length) {
          const totalCountSum = allResponses.reduce((acc, resp) => {
            return acc + (resp.metadata.page?.totalResults ?? 0)
          }, 0)
          this.patchState({
            totalCount: totalCountSum
          })
        }
      }),
      finalize(() => this.ctx.patchState({ isLoading: false }))
    )
  }

  loadCurrentPatientInsights(
    page: number,
    freeTextSearch: string,
    patientId: string,
    status?: string
  ) {
    return this.backendService
      .getBrowsingInsights(page, freeTextSearch, patientId, undefined, status)
      .pipe(
        tap((res: InsightsBackend) => {
          const insights = Object.values(this.getState().entities)
          this.removeAll()
          if (!res.data?.length && freeTextSearch.length) {
            this.upsertMany([])
          } else {
            if (page === 0) {
              this.upsertMany(
                orderBy([...res.data], 'insightStartTime', 'desc')
              )
            } else {
              this.upsertMany(
                orderBy([...insights, ...res.data], 'insightStartTime', 'desc')
              )
            }
          }
          this.ctx.patchState({ isLoading: false })
          if (!freeTextSearch) {
            this.patchState({
              totalCount: res.metadata.page?.totalResults
            })
          }
        })
      )
  }

  searchInsightPatientByNameAndRoom(freeTextSearch: string): PatientDTO[] {
    const department =
      this.departmentState.snapshot.currentDepartment ||
      this.departmentState.entities[0]
    let patients = Object.values(this.patientState.entities)
    if (department && department.id !== DepartmentFilter.All) {
      patients = entitiesFilter(
        freeTextSearch,
        patients.filter(
          patient =>
            patient.department && patient.department.id === department.id
        )
      )
    } else {
      patients = entitiesFilter(
        freeTextSearch,
        patients.filter(patient => patient.department)
      )
    }
    return patients
  }

  @DataAction()
  public getPatientInsightsTotalResult() {
    const ids: string[] = this.searchInsightPatientByNameAndRoom('').map(
      p => p.id
    )
    return combineLatest(
      this.backendService.getBrowsingCombineInsights(0, ids),
      this.backendService.getBrowsingInsights(0, '', undefined, ids, undefined)
    ).pipe(
      tap(([active, all]) =>
        this.insightsAllActiveTotalCount$.next({
          allTotalCount: all?.metadata?.page?.totalResults || 0,
          activeTotalCount: active?.metadata?.page?.totalResults || 0
        })
      )
    )
  }

  clearPatientInsightsTotalResult() {
    this.insightsAllActiveTotalCount$.next({
      allTotalCount: 0,
      activeTotalCount: 0
    })
  }

  @DataAction()
  public loadPatientCombineInsights(
    @Payload('page') page: number,
    @Payload('freeTextFilter') freeTextSearch: string
  ) {
    this.ctx.patchState({
      isLoading: true
    })
    const patients = this.searchInsightPatientByNameAndRoom(freeTextSearch)
    const ids = patients.map(p => p.id)
    const chunkedIds = this.backendService.chunkSetting(ids)
    return from(chunkedIds).pipe(
      concatMap(partIds =>
        this.backendService.getBrowsingCombineInsights(page, partIds)
      ),
      reduce((acc, res: InsightsBackend) => {
        acc.push(res)
        return acc
      }, [] as InsightsBackend[]),
      tap((allResponses: InsightsBackend[]) => {
        const combinedData = allResponses.flatMap(r => r.data)
        const insights = Object.values(this.getState().entities)
        this.removeAll()

        if (!ids.length && freeTextSearch.length) {
          this.upsertMany([])
        } else {
          const dataForUpsert =
            page === 0 ? combinedData : [...insights, ...combinedData]
          this.upsertMany(
            orderBy(
              dataForUpsert,
              [
                item => (item.insightStatus === 'ongoing' ? 0 : 1),
                'insightStartTime'
              ],
              ['asc', 'desc']
            )
          )
        }

        if (!freeTextSearch) {
          const totalCount = allResponses.reduce((acc, resp) => {
            return acc + (resp.metadata.page?.totalResults ?? 0)
          }, 0)
          this.patchState({
            totalCount
          })
        }
      }),
      finalize(() => {
        this.ctx.patchState({
          isLoading: false
        })
      })
    )
  }

  @DataAction()
  public setLoading(@Payload('isLoading') isLoading: boolean) {
    this.patchState({ isLoading })
  }

  @DataAction()
  public loadPatientInsightsForThreeMonth(
    @Payload('patientId') patientId: string,
    @Payload('insightId') insightId: string,
    @Payload('subject') subject: string,
    @Payload('outlineType') outlineType: string
  ) {
    this.setMany([])
    return (this.subscriptionGetAllInsights$ = this.backendService
      .getAllInsightsBrowsingRecursively(0, patientId)
      .subscribe(res => {
        if (!res) {
          this.subscriptionGetAllInsights$.unsubscribe()
          return
        }
        this.setMany(
          res.data.filter(
            i =>
              i.id !== insightId &&
              i.insightSubject === subject &&
              i.outlineType === outlineType
          )
        )
      }))
  }

  @DataAction()
  public getPatientInsight(@Payload('id') id: string) {
    this.ctx.patchState({
      isLoading: true
    })
    return this.backendService.getBrowsingInsight(id).pipe(
      tap((res: InsightDTO[]) => {
        const currentInsights = res.find(insight => insight.id === id)
        if (currentInsights) {
          this.patientState.focusOnPatient(currentInsights.patient.id)
          this.patchState({
            currentInsights
          })
        }
      })
    )
  }

  protected setPaginationSetting(): Observable<any> {
    return EMPTY
  }

  protected loadEntitiesFromBackend(
    ids: string[] | undefined
  ): Observable<void> {
    return EMPTY
  }
}
