<section
  [attr.data-cy]="'aiomed-warning-modal'"
  [class.mobile]="isMobile"
  [style.--modal-width]="width"
  [style.--zindex]="zIndex"
  [style.--_icon-color]="iconColor"
  [style.--_icon-size]="iconSize"
  [style.--_icon-background]="iconBackground"
  class="warning-modal">
  <div
    [class.container-session-desktop]="isSessionExpired || isDeleteNotification"
    [class.no-title]="!isTitleShown"
    class="warning-modal__container">
    <div class="warning-modal__title-box">
      <ng-container
        [ngTemplateOutlet]="iconTemplate || defaultIconTemplate"></ng-container>
      <ng-template #defaultIconTemplate>
        <i
          [class.icon-session-desktop]="
            isSessionExpired || isDeleteNotification
          "
          nz-icon
          [nzType]="iconType || 'aio:warning'"></i>
      </ng-template>
      @if (isTitleShown) {
        <div
          [class.normal]="isNormalTitle"
          [class.title-session-desktop]="
            isSessionExpired || isDeleteNotification
          "
          class="title"
          [class.tablet]="isMobile">
          {{ title }}
        </div>
      }
    </div>
    <div
      [class.text-delete-notification]="isDeleteNotification"
      [class.text-session-desktop]="isSessionExpired"
      [class.no-title]="!isTitleShown"
      [style.--description-width]="descriptionWidth"
      class="text"
      [class.tablet]="isMobile">
      <ng-container
        [ngTemplateOutlet]="
          descriptionTemplate || defaultTemplate
        "></ng-container>
      <ng-template #defaultTemplate>
        {{ description }}
      </ng-template>
    </div>
    <div
      [class.button-box-session-desktop]="
        isSessionExpired || isDeleteNotification
      "
      [class.vertical]="isButtonsVertical"
      class="warning-modal__button-box"
      [class.tablet]="isMobile">
      <button
        [attr.data-cy]="'warning-close'"
        (click)="closeModalEmitter.emit(false)"
        [class.dark-btn]="hideSave || isFirstButtonDark"
        [class.tablet]="isMobile">
        {{ closeText }}
      </button>
      @if (!hideSave) {
        <button
          [attr.data-cy]="'warning-save'"
          class="save"
          [class.tablet]="isMobile"
          [attr.aria-disabled]="isSaveDisabled"
          (click)="
            isSaveDisabled
              ? $event.preventDefault()
              : saveModalDataEmitter.emit(true)
          "
          [nzLoading]="isLoading"
          nz-button
          nzType="primary">
          {{ saveText }}
        </button>
      }
    </div>
  </div>
</section>
