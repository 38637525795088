<div [class.opened]="isOpened" [class.tablet]="isTabletView" class="sidemenu">
  <div [class.desktop]="!isTabletView" [class.rn]="isUserRN" class="avatar">
    @if (getNotificationItem?.isNFType && !isTabletView) {
      <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
    }
    <div
      (click)="avatarClick.emit()"
      [nzPopoverVisible]="popoverVisible"
      (nzPopoverVisibleChange)="popoverVisibleChange.emit($event)"
      [class.tablet]="isTabletView"
      [nzPopoverContent]="nzPopoverContent"
      class="avatar__content"
      nz-popover
      nzPopoverOverlayClassName="current-user-setting-popover"
      nzPopoverPlacement="bottomRight"
      nzPopoverTrigger="click">
      <lib-avatar
        [nzSize]="isTabletView ? 86 : 40"
        [nzSrc]="user.avatar"
        [nzText]="user.name" />
      <span [class.desktop]="!isTabletView" class="sidemenu-text content">
        <span class="name">{{ user.name }}</span>
        <!-- @if (departmentName) {
          <span class="department">{{ departmentName }}</span>
        } -->
        @if (deviceName) {
          <span class="device">Selected device: {{ deviceName }}</span>
        }
      </span>
    </div>
  </div>
  <ul class="sidemenu-list">
    <ng-container *ngFor="let item of items; let index = index">
      <li
        (click)="onListItemClick(item, index)"
        *ngIf="!item.isNFType"
        class="sidemenu-list__item">
        <a
          [routerLinkActiveOptions]="{ exact: item?.exact || false }"
          [routerLink]="item.link"
          class="sidemenu-list__link"
          routerLinkActive="active">
          <nz-badge
            [nzCount]="item?.badgeCount"
            [nzSize]="'default'"
            class="badge">
            <span
              *ngIf="item?.icon as icon"
              [class.desktop]="!isTabletView"
              [nzTheme]="item.iconTheme || 'outline'"
              [nzType]="icon"
              class="icon"
              nz-icon></span>
            <span
              *ngIf="item?.title as title"
              [class.desktop]="!isTabletView"
              class="sidemenu-text"
              >{{ title | translate }}</span
            >
          </nz-badge>
        </a>
      </li>
    </ng-container>
  </ul>
  @if (customTemplateContent) {
    <ng-container *ngTemplateOutlet="customTemplateContent"></ng-container>
  }
  <ng-template #notificationContent>
    <div class="sidemenu-list__item sidemenu-is-notification">
      <a
        [routerLink]="getNotificationItem?.link"
        class="sidemenu-list__link"
        routerLinkActive="active">
        <nz-badge
          [nzCount]="getNotificationItem?.badgeCount"
          [nzSize]="'default'"
          class="badge">
          @if (getNotificationItem && getNotificationItem.icon) {
            <span
              [nzType]="getNotificationItem.icon"
              class="icon"
              nz-icon></span>
          }
          @if (getNotificationItem && getNotificationItem.title) {
            <span class="sidemenu-text">{{
              getNotificationItem.title | translate
            }}</span>
          }
        </nz-badge>
      </a>
    </div>
  </ng-template>
  @if (!logoutHidden) {
    <ul class="sidemenu-logout" [class.desktop]="!isTabletView">
      <li class="sidemenu-logout__item" (click)="logout.emit()">
        <nz-badge class="badge">
          <span
            class="icon"
            [class.desktop]="!isTabletView"
            nz-icon
            nzType="logout"
            nzTheme="outline"></span>
          <span class="sidemenu-text" [class.desktop]="!isTabletView">{{
            logoutText
          }}</span>
        </nz-badge>
      </li>
    </ul>
  }
</div>
