import { round } from 'lodash-es'
import { ObservationFields } from '../../shared/model/patient-observation'

interface CriticalRange {
  hMin: number
  hMax: number
  lMin: number
  lMax: number
}

type CriticalRules = Partial<Record<ObservationFields, CriticalRange>> & {
  getRange: (type: ObservationFields) => CriticalRange
  inRange: (value: number, min: number, max: number) => boolean
  checkVitalToCriticalRule: (
    value: number | undefined | null,
    type: ObservationFields
  ) => boolean
}

export const checkCriticalRules: CriticalRules = {
  [ObservationFields.HeartRate]: {
    hMin: 121,
    hMax: 150,
    lMin: 40,
    lMax: 49
  },
  [ObservationFields.SpO2]: {
    hMin: 80,
    hMax: 88,
    lMin: 0,
    lMax: 0
  },
  [ObservationFields.DiastolicPressure]: {
    hMin: 121,
    hMax: 130,
    lMin: 40,
    lMax: 49
  },
  [ObservationFields.SystolicPressure]: {
    hMin: 186,
    hMax: 220,
    lMin: 70,
    lMax: 89
  },

  getRange(type: ObservationFields): CriticalRange {
    return this[type] ?? { hMin: 0, hMax: 0, lMin: 0, lMax: 0 }
  },
  inRange(value: number, min: number, max: number) {
    return value >= min && value <= max
  },
  checkVitalToCriticalRule(
    value: number | null | undefined,
    type: ObservationFields
  ): boolean {
    const roundedValue = round(value || 0, 1)
    const { hMin, hMax, lMin, lMax } = this.getRange(type)
    const inHigh = this.inRange(roundedValue, hMin, hMax)
    const inLow = this.inRange(roundedValue, lMin, lMax)
    return inHigh || inLow
  }
}
