import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  inject,
  OnInit,
  viewChildren
} from '@angular/core'
import { routingAnimation } from '../../../../../shared/animations/routing-animation'
import { AuthState } from '../../../../../store/auth/auth.state'
import { Store } from '@ngxs/store'
import { ErrorState } from '../../../../../store/error/error.state'
import { Observable, take, timer } from 'rxjs'
import { MfaLoginRequestV2 } from '@biot-client/biot-client-ums'
import { NzMessageService } from 'ng-zorro-antd/message'
import { PreferenceState } from '../../../../..//store/preference/preference.state'
import { DeviceState } from '../../../../..//store/device/device.state'
import { DeviceDTO } from '../../../../..//shared/model/device.model'
import { DepartmentDTO } from 'projects/aiomed/src/shared/model/permission.model'
import { ActivatedRoute, Router } from '@angular/router'
import { filter, tap } from 'rxjs/operators'
import LogRocket from 'logrocket'
import {
  PCC_CLOSE_WINDOW_KEY,
  PCC_CLOSE_WINDOW_REASON_KEY
} from 'projects/aiomed/src/shared/constants/pcc-close-window-duration.constant'
import { PccCloseWindowReason } from 'projects/aiomed/src/shared/enums/pcc-window-close-reason.enum'
import { getRedirectDataToLocalStorageTransformToQueryParams } from '../../../../../core/helpers/check-redirect-setting'
import { reloadImage } from 'projects/aiomed/src/core/helpers/refetch-image'
import { NetworkService } from 'projects/aiomed/src/shared/services/network.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [routingAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class LoginComponent implements OnInit {
  private readonly networkService = inject(NetworkService)
  imagesToRefetchOnceOnline =
    viewChildren<ElementRef<HTMLImageElement>>('imageToRefetch')
  errorMessage$: Observable<string | null> = inject(Store).select(
    ErrorState.errorMessage
  )
  isMfaRequired$: Observable<boolean> = inject(Store).select(
    AuthState.isMfaRequired
  )
  needChooseDevice$: Observable<boolean> = inject(Store).select(
    AuthState.needChooseDevice
  )
  sharedDevices$: Observable<DeviceDTO[]> = inject(Store).select(
    DeviceState.allDevices
  )
  deviceLoading$: Observable<boolean> = inject(Store).select(
    DeviceState.isLoading
  )
  isAuthLoading$: Observable<boolean> = inject(Store).select(
    AuthState.isLoading
  )
  isPccReturning$: Observable<boolean> = inject(Store).select(
    AuthState.isPccReturning
  )
  source$: Observable<Event>
  public selectedDeviceId: string = ''
  @HostBinding('@routingAnimation') private routing: any
  public isMobile = this.preferenceState.isMobile
  public isLoginWithCred = false

  constructor(
    private authState: AuthState,
    private message: NzMessageService,
    private preferenceState: PreferenceState,
    private route: ActivatedRoute,
    private readonly router: Router
  ) {
    this.checkNotificationRedirectSetting()
    this.reloadImagesOnceOnline()
  }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        tap(params => {
          if (params?.['error']) {
            this.authState.showPCCAuthorizationError(params['error'])
            setTimeout(() => {
              this.router.navigate([], {
                queryParams: {
                  error: null
                },
                queryParamsHandling: 'merge'
              })
            }, 500)
          }
        }),
        take(1),
        filter(params => params['refreshToken'])
      )
      .subscribe(data => {
        if (!data || (!data['refreshToken'] && this.isMobile)) {
          return
        }
        this.authState.setIfLoggingFromPcc(true)
        this.authState.loginWithPccIntoSystem(data['refreshToken'])
      })
  }

  handlerFormSubmitEmitter($event: any): void {
    this.authState.login($event)
  }

  handlerFormMfaSubmitEmitter($event: MfaLoginRequestV2): void {
    this.authState.mfaLogin($event)
  }

  handlerResendMfaCodeEmitter(): void {
    this.authState.mfaResend()
    this.message.info('New code sent to phone')
  }

  handlerArrowClick(): void {
    this.authState.resetMfaRequired(false)
    this.authState.logout()
  }

  handlerFormDepartmentSubmitEmitter(department: DepartmentDTO | null) {
    this.authState.canLogIntoSystem(false, department)
  }

  setNeedChooseDepartment(): void {
    if (this.authState.isLoginFromPCC) {
      this.preferenceState.setPccLoginWasPerformedWithCNAUser(true)
      this.authState.setIsPccReturning(false)
      LogRocket.track(PCC_CLOSE_WINDOW_KEY, {
        [PCC_CLOSE_WINDOW_REASON_KEY]: PccCloseWindowReason.PccLoginWithCNAuser
      })
    } else {
      this.message.warning('You do not have access rights')
    }
    this.authState.setIfLoggingFromPcc(false)
    this.authState.resetTokens()
  }

  public setCurrentDeviceIdAndLogin(): void {
    this.preferenceState.setCurrentDeviceId(this.selectedDeviceId)
    this.authState.canLogIntoSystem(true)
  }

  handlerLoginWithPcc() {
    if (this.isMobile) {
      this.authState.setIsPccReturning(true)
      timer(500)
        .pipe(take(1))
        .subscribe(() => {
          this.authState.loginWithPcc('login-tablet')
        })
      return
    }
    this.authState.loginWithPcc('desktop')
  }

  private checkNotificationRedirectSetting() {
    const { hasData, queryParams } =
      getRedirectDataToLocalStorageTransformToQueryParams()
    if (hasData) {
      this.router.navigate(['/login'], {
        queryParams
      })
    }
  }

  private reloadImagesOnceOnline() {
    this.networkService.isOnlineObs$
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        if (this.imagesToRefetchOnceOnline()) {
          this.imagesToRefetchOnceOnline().forEach(image => {
            const img = image.nativeElement
            if (!img.complete || img.naturalWidth === 0) {
              reloadImage(img)
            }
          })
        }
      })
  }
}
