import { NgIf, NgTemplateOutlet } from '@angular/common'
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  inject
} from '@angular/core'
import { FormsModule } from '@angular/forms'
import { BreakpointObserver } from '@angular/cdk/layout'
import { Subject, takeUntil } from 'rxjs'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzSelectComponent, NzSelectModule } from 'ng-zorro-antd/select'
import { AlertsListComponent } from './alerts-list/alerts-list.component'
import { AlertListItem } from './interfaces/alert-list-item.interface'
import { BasePatient } from './interfaces/base-patient.interface'
import { NzAvatarModule } from 'ng-zorro-antd/avatar'
import { AgePipe } from '../shared'

@Component({
  selector: 'lib-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NzButtonModule,
    NzIconModule,
    AlertsListComponent,
    NzSelectModule,
    FormsModule,
    NgTemplateOutlet,
    NzAvatarModule,
    AgePipe
  ]
})
export class HeaderComponent<T extends BasePatient = BasePatient>
  implements AfterViewInit
{
  @ViewChild('select', { read: ElementRef })
  select: ElementRef<NzSelectComponent>
  @Output() toggleSideMenu: EventEmitter<void> = new EventEmitter<void>()
  @Output('searchPatientEmitter') searchPatient: EventEmitter<string> =
    new EventEmitter<string>()
  @Output() modelChange: EventEmitter<string> = new EventEmitter<string>()
  @Input({ required: false }) pageTitle: string
  @Input({ required: false }) pageTitleTemplate: TemplateRef<unknown>
  @Input() isOpened: boolean | null = false
  @Input() alertsItems: AlertListItem[] | null = []
  @Input() searchPatients: T[] | null = []
  @Input() selectPlaceholder: string = 'Search Patient'
  @Input() searchTabletWidth: number = 160
  @Input() searchDesktopWidth: number = 320
  @Input() isRightContent: boolean | null = false
  @Input() isMiddleContent: boolean | null = false
  @Input() customMiddleContent?: TemplateRef<any>
  @Input() customRightContent?: TemplateRef<any>
  @Input() isLarge: boolean | null = true
  @Input() isBlueMode: boolean | null = false
  @Input() togglerDisabled: boolean | null = false
  @Input() hasPairedDevice: boolean | null = false

  private destroy$ = new Subject<void>()
  private cdr = inject(ChangeDetectorRef)
  private breakpointObserver = inject(BreakpointObserver)
  @Input() isMobile: boolean | undefined = undefined
  public isTabletView: boolean
  public textSearch: string = ''
  public isFocused: boolean = false

  ngAfterViewInit(): void {
    this.breakpointObserver
      .observe([
        '(max-width:1024px) and (orientation: portrait)',
        '(max-width:1280px) and (orientation: landscape)'
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (this.isOpened) {
          this.onToggleSideMenu()
        }
        if (this.isMobile !== undefined) {
          this.isTabletView = this.isMobile
          this.cdr.detectChanges()
          return
        }
        if (result.matches) {
          this.isTabletView = true
        } else {
          this.isTabletView = false
        }
        this.cdr.detectChanges()
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  public onToggleSideMenu(): void {
    this.toggleSideMenu.emit()
  }
  public onSearchPatient(event: string): void {
    this.searchPatient.emit(event.trim())
    this.textSearch = event
  }

  public onModelChange(event: string): void {
    this.modelChange.emit(event)
    this.textSearch = event
    setTimeout(() => {
      this.textSearch = ''
      this.modelChange.emit('')
    }, 10)
  }

  public fieldFocus(): void {
    this.isFocused = true
    this.select.nativeElement.focus()
    this.textSearch = ''
    this.modelChange.emit('')
  }

  public fieldBlur(): void {
    this.isFocused = false
  }
}
