import { inject, Injectable } from '@angular/core'
import {
  BehaviorSubject,
  debounce,
  distinctUntilChanged,
  map,
  shareReplay,
  timer
} from 'rxjs'
import { ConfigService } from './config.service'
import { FeatureParams } from '../model/config'
import moment from 'moment'

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  private readonly configService = inject(ConfigService)
  private readonly debounceTime = this.configService.getNumberValue(
    FeatureParams.FeatureOfflineDebounceTime,
    300000
  )
  private isOnline$ = new BehaviorSubject<boolean>(true)
  public readonly isOnlineObs$ = this.isOnline$
    .asObservable()
    .pipe(
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    )
  public readonly isOfflineDebounced$ = this.isOnlineObs$.pipe(
    debounce(isOnline => {
      if (isOnline === true) {
        return timer(0)
      }
      const debounceTime = moment().add(this.debounceTime, 'milliseconds')
      return timer(debounceTime.toDate())
    }),
    map(isOnline => !isOnline),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  public setOnline(): void {
    this.isOnline$.next(true)
  }

  public setOffline(): void {
    this.isOnline$.next(false)
  }
}
